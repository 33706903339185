<template>
  <!-- Legend -->
  <custom-legend :items="userLegendItems" />
</template>

<script>
/* eslint-disable no-undef */
import CustomLegend from "./CustomLegend.vue";

export default {
  name: "UsersListLegend",

  components: { CustomLegend },

  data: () => ({
    userLegendItems: [
      { icon: "far fa-credit-card-blank grey--text", text: "Free Member" },
      {
        icon: "fas fa-credit-card accent--text",
        text: "Trial/Limited-time Member"
      },
      { icon: "fas fa-credit-card grey--text", text: "Drop-in Member" },
      { icon: "fas fa-credit-card success--text", text: "Subscriber" },
      { icon: "fas fa-spa primary-dark--text", text: `${APP_ORG} Employee` },
      { icon: "fas fa-video success--text", text: "Zoom Acct Linked" }
    ]
  })
};
</script>

<style lang="scss" scoped>
.legend {
  @include slide-down-fade-in;
  animation-duration: 300ms;
  font-size: smaller;
}
</style>
