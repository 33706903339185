<template>
  <div
    :class="{ 'user-image image--centered': true, selectable }"
    role="button"
    @click.stop="$emit('image-click')"
  >
    <image-loader
      v-if="imageSrc"
      :alt="imageAlt"
      :image-url="imageSrc"
      @image-loaded="stopLoading"
    />

    <!-- Icon alt -->
    <i
      v-else
      class="fas photo-icon pulse"
      :class="{
        'accent--text': trialMembership,
        'fa-user-circle': !employee,
        'fa-spa primary-dark--text': employee,
        'grey-light--text': !trialMembership && !subscriber && !employee,
        'success--text': subscriber
      }"
    />

    <!-- Subscriber icon -->
    <span class="subscriber" v-if="subscriber">
      <i title="Subscriber" class="fas fa-credit-card" />
      <span class="hover-text">Subscriber</span>
    </span>
  </div>
</template>

<script>
import ImageLoader from "./ImageLoader.vue";

export default {
  components: { ImageLoader },

  name: "UserProfileImage",

  props: {
    imageAlt: { type: String, default: "User image" },
    imageSrc: String,
    employee: Boolean,
    selectable: Boolean,
    subscriber: Boolean,
    trialMembership: Boolean,
    userName: { type: String, default: "User" }
  },

  data: () => ({
    loading: true,
    loadingMessage: "Loading image..."
  }),

  mounted() {
    if (!this.imageSrc) return this.stopLoading();
  },

  methods: {
    stopLoading() {
      this.loading = false;
      this.loadingMessage = null;
    }
  }
};
</script>

<style lang="scss">
$img-height: 8rem;

.user-image {
  @include center-text;
  height: $img-height;

  &.inline {
    &,
    img {
      max-height: 2.2rem;
      max-width: 2.2rem;
    }

    img {
      padding: $xtiny;
    }
  }

  &.selectable {
    cursor: pointer;
  }

  .photo-icon {
    font-size: $img-height;
  }

  .hover-text {
    @include elevated(4px);
    @include rounded;
    @include slide-up-fade-out();
    @include h6();
    animation-fill-mode: forwards;
    background: white;
    color: $grey;
    letter-spacing: $xtiny;
    padding: $tiny;
    position: absolute;
    pointer-events: none;
    top: 2rem;
    visibility: none;
    z-index: 999;
  }

  .subscriber {
    @include elevated(2px);
    align-items: center;
    background-color: $success-light;
    border-radius: $md;
    color: white;
    display: inline-flex;
    font-size: $xs;
    height: $md;
    margin-left: -$sm;
    padding: $tiny;
    place-content: center;
    width: $md;

    &:hover .hover-text {
      @include slide-down-fade-in();
      visibility: visible;
    }
  }

  img {
    @include elevated;
    border-radius: $img-height;
    height: $img-height;
    overflow: hidden;
    padding: $xxxs;
    width: auto !important;
  }
}
</style>
