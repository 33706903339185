<template>
  <component
    :is="headerItem || !user ? 'div' : 'router-link'"
    :to="user && { name: 'EditUser', params: { userId: user.id } }"
    :class="`h6 list-item list-item--user${user ? '' : ' list-item--header'}`"
  >
    <!-- Name -->
    <div class="fullname inline grow ellipsis">
      <template v-if="!headerItem">
        <user-profile-image
          class="inline"
          :employee="(user || {}).employee"
          :image-alt="`${fullName} image`"
          :image-src="user.userImage"
          :selectable="false"
          :subscriber="(user || {}).stripeSubActive"
          :trial-membership="(user || {}).trialMembershipActive"
        />

        <hr class="divider divider--vertical" />
      </template>

      <strong>{{ fullName }}</strong>
    </div>

    <!-- Zoom/Stripe User -->
    <span class="icons">
      <i :class="stripeClass"></i>
      <hr class="divider divider--vertical" />
      <i :class="zoomClass"></i>
      <hr class="divider divider--vertical" />
    </span>

    <!-- Role -->
    <span class="role">{{ role }}</span>

    <!-- Username -->
    <span class="username link">{{ username }}</span>
  </component>
</template>

<script>
import UserProfileImage from "./UserProfileImage.vue";

export default {
  name: "UserListItem",

  components: { UserProfileImage },

  props: { headerItem: Boolean, user: Object },

  computed: {
    fullName() {
      return this.user ? this.user.fullName : "Full Name";
    },

    role() {
      return this.user ? this.user.role : "Role";
    },

    stripeClass() {
      const user = this.user || {};
      const { trialMembershipActive, stripeAcctId, stripeSubActive } = user;

      return {
        "accent--text": trialMembershipActive,
        "far grey--text": !trialMembershipActive && !stripeSubActive,
        fas: stripeAcctId || trialMembershipActive,
        "fa-credit-card": stripeAcctId || trialMembershipActive,
        "fa-credit-card-blank": !stripeAcctId && !trialMembershipActive,
        "success--text": stripeSubActive
      };
    },

    zoomClass() {
      const user = this.user || {};
      return {
        "fas fa-video": true,
        "success--text": user.zoomUserId,
        "grey-light--text": !user.zoomUserId
      };
    },

    username() {
      return this.user ? this.user.username : "Username";
    }
  }
};
</script>

<style lang="scss">
.list-item--user {
  &.h6 {
    color: #000;
    font-weight: normal;
  }

  .user-image {
    .photo-icon {
      font-size: 2.1rem;
    }

    .subscriber {
      display: none;
    }
  }
  .icons {
    display: inline-flex;
    flex-shrink: 0;
  }

  .image-loader--loading {
    top: 0;
    left: 0;
    height: 2rem;
    width: 2rem;
  }

  .role {
    font-size: $xs;
    text-transform: uppercase;
  }

  .role,
  .username {
    flex-shrink: 0;
    letter-spacing: $xtiny;
    width: $quarter;
  }
}

@media screen and (max-width: 600px) {
  .list-item--user {
    height: auto;
    padding-bottom: $xxs;
    padding-top: $xxs;
    flex-wrap: wrap;

    .fullname {
      width: $half;
      order: 1;
    }

    .icons,
    .role {
      order: 0;
    }

    .icons {
      width: $half;
    }

    .role {
      @include ellipsis;
      text-align: right;
      max-width: $half;
    }

    .username {
      order: 10;
      flex-shrink: 0;
      width: auto;
    }
  }
}
</style>
